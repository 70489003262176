import * as R from 'ramda'

import { mockChannel_default } from '../../constants/ris.mjs'

export const risV1InitAppendData = sn => ({
  deviceControl: {
    '4G7': {
      // ...createPortData('ris_1', 16),
      ...createPortData('ris_2', 16),
      ...createPortData('ris_3', 16),
      ...createPortData('ris_4', 16),
    },
    '28G': {
      ...createPortData('ris_1', 32),
      // ...createPortData('ris_2', 32),
      // ...createPortData('ris_3', 32),
      // ...createPortData('ris_4', 32),
    },
  },
})

const createPortData = (name, channelCount = 32) => ({
  [name]: R.clone({
    steering: {
      incident: {
        // 因爲 source 有可能不成 beam 所以沒取名叫 beamIndex
        index: 0,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
        distance: 0,
        distanceMin: 0,
        distanceMax: 500,
      },
      reflection: {
        index: 0,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
      },
    },
    channel:
      channelCount === 32
        ? mockChannel_default
        : new Array(channelCount).fill(new Array(channelCount).fill(1)),
  }),
})
